import { HTTPError } from "ky";
import { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSitemap } from "react-icons/fa";
import { Link, Path, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { TextSpan } from "typescript";
import { DissociateChecklistFromNodeEvent } from "../../../core/constants/application-insights-events";
import {
    ChecklistQuestions,
    Common,
    Hierarchy,
    HierarchyTypes,
    Nodes,
    NodeTypes,
    NodeTypeValues,
    NonWorkingDays,
    UserManagement,
    Users,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    createNavigateToForSearch,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import { createSuccessToastProps, useToast } from "../../../core/store/toast-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    maxContentWidthSelectStyle,
    NoDataStateDiv,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import {
    associatedNodesColumnNames,
    nameColumnNames,
} from "../../../core/utilities/dataTableColumns";
import { toDateAndTimeFormat } from "../../../core/utilities/date-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areMutationsLoading,
    areQueriesLoading,
    areQueriesSuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import { defaultPaginationDto, PaginationDto } from "../../../domain/dtos/common/pagination-dto";
import { BaseNodeDto } from "../../../domain/dtos/hierarchy/base-node-dto";
import { NonWorkingDaySetDto } from "../../../domain/dtos/non-working-days/non-working-day-set-dto";
import { BaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import {
    ChecklistRoleGroup,
    hasRoleTypeInGroup,
    NodeRoleGroup,
    UserRoleGroup,
} from "../../../domain/enums/Roles";
import { useDissociateChecklistFromNodeByChecklistId } from "../../../domain/viewmodels/checklists/dissociate-node-viewmodel";
import { createUserNodeAssociationParameters } from "../../../domain/viewmodels/hierarchy/dissociate-user-viewmodel";
import {
    useAssignUserToNode,
    useFilterUsers,
    useGetAssignedToNodeDetails,
    useGetLinkedNodeDetails,
    useGetNodeAndChildNodeDetails,
    useHasLinkedToNodeTypes,
} from "../../../domain/viewmodels/hierarchy/node-details-viewmodel";
import SbCheckField from "../../atoms/input/SbCheckField";
import { SbSelect } from "../../atoms/input/SbSelect";
import { AssociateButton } from "../../atoms/SbButton";
import {
    ActivateLink,
    CreateLink,
    DemoteLink,
    EditLink,
    PromoteLink,
    SbLink,
    SuspendLink,
} from "../../atoms/SbLink";
import { SbRibbon } from "../../atoms/SbRibbon";
import { SbAccordion } from "../../molecules/SbAccordion";
import SbModal from "../../molecules/SbModal";
import { DataTable } from "../../organisms/DataTable";
import AssignedActionItemsToNodeContainer from "./AssignedActionItemsToNodeContainer";

const NodeDetailsContainer = (): JSX.Element => {
    const [childNodesPaginationDto, setChildNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [linkedNodesPaginationDto, setLinkedNodesPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [assignedUsersPaginationDto, setAssignedUsersPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [assignedChecklistsPaginationDto, setAssignedChecklistsPaginationDto] =
        useState<PaginationDto>(defaultPaginationDto);
    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const [assigningUser, setAssigningUser] = useState<BaseUserDto | null>(null);
    const [showDissociateChecklistAssignmentModal, setShowDissociateChecklistAssignmentModal] =
        useState<boolean>(false);
    const [dissociateChecklistId, setDissociateChecklistId] = useState<number | null>(null);

    const menu = useMenu();
    const auth = useAuth();
    const toast = useToast();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: Nodes });

    const success = urlSearchParams.get("success") === "true" ? true : false;
    const messageKey = urlSearchParams.get("messageKey") ?? "";
    const nodeId = Number(useParams().nodeId);

    const getNodeAndChildNodeDetails = useGetNodeAndChildNodeDetails(
        nodeId,
        childNodesPaginationDto
    );
    const filterUsers = useFilterUsers(1, 100, userSearchText);
    const assignUserToNode = useAssignUserToNode();
    const dissociateChecklistFromNodeByChecklistId = useDissociateChecklistFromNodeByChecklistId();

    const nodeDetailsResponse = getNodeAndChildNodeDetails[0];
    const childNodesResponse = getNodeAndChildNodeDetails[1];

    const nodeDetailsResponseData = nodeDetailsResponse.data;
    const nodeResponseData = nodeDetailsResponseData?.node;
    const childNodesResponseData = childNodesResponse.data;

    const getHasLinkedToNodeTypes = useHasLinkedToNodeTypes(
        nodeResponseData?.nodeType.nodeTypeId ?? null
    );

    const getLinkedNodeDetails = useGetLinkedNodeDetails(
        nodeId,
        getHasLinkedToNodeTypes.data ?? null,
        linkedNodesPaginationDto
    );

    const hasLinkedToNodeTypesResponseData = getHasLinkedToNodeTypes.data;
    const linkedNodesResponseData = getLinkedNodeDetails.data;

    const getAssignedToNodeDetails = useGetAssignedToNodeDetails(
        nodeId,
        nodeResponseData?.isUserAssignable ?? null,
        assignedUsersPaginationDto,
        assignedChecklistsPaginationDto
    );

    const assignedUsersResponse = getAssignedToNodeDetails[0];
    const assignedChecklistsResponse = getAssignedToNodeDetails[1];

    const assignedUsersResponseData = assignedUsersResponse.data;
    const assignedChecklistsResponseData = assignedChecklistsResponse.data;

    useLoader(
        areQueriesLoading([
            ...getNodeAndChildNodeDetails,
            getHasLinkedToNodeTypes,
            getLinkedNodeDetails,
            ...getAssignedToNodeDetails,
        ]) || areMutationsLoading([assignUserToNode, dissociateChecklistFromNodeByChecklistId]),
        NodeDetailsContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);

        if (success) {
            toast.addToast(createSuccessToastProps([t(messageKey)]));

            urlSearchParams.delete("success");
            urlSearchParams.delete("messageKey");
            setUrlSearchParams(urlSearchParams);
        }
    }, []);

    // Field conditionals
    const showSuspensionDates = (suspensionDate: Date | null): boolean =>
        nodeResponseData!.isSuspended || suspensionDate != null;

    const showInheritedNonWorkingDaySetData = (): boolean =>
        nodeResponseData!.nonWorkingDaySet == null &&
        nodeDetailsResponseData!.inheritedNonWorkingDaySet != null;

    const isLegacyIdPresent = (): boolean => nodeResponseData!.legacyId != null;

    const showInheritedTrainingFlagData = (): boolean =>
        !nodeResponseData!.isTraining && nodeDetailsResponseData!.inheritedTrainingFlag;

    const isTrainingNode = (): boolean =>
        showInheritedTrainingFlagData()
            ? nodeDetailsResponseData!.inheritedTrainingFlag
            : nodeResponseData!.isTraining;

    const onAssignUser = (): void => {
        if (assigningUser) {
            assignUserToNode.mutate(
                createUserNodeAssociationParameters(nodeId, assigningUser.userId),
                {
                    onSuccess: onAssignUserSuccess,
                    onError: errorResponseToDisplayHandler,
                }
            );
        }
    };

    const onAssignUserSuccess = async (): Promise<void> => {
        setAssigningUser(null);

        toast.addToast(
            createSuccessToastProps([
                t("AssignUserToNodeSuccessMessage", { keyPrefix: UserManagement }),
            ])
        );

        queryClient.refetchQueries(["filterUsersByNode", nodeId, assignedUsersPaginationDto]);
    };

    const handleDissociateChecklist = (): void => {
        dissociateChecklistFromNodeByChecklistId.mutate(
            {
                checklistId: dissociateChecklistId!,
                nodeId: nodeId!,
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        DissociateChecklistFromNodeEvent
                    );

                    toast.addToast(
                        createSuccessToastProps([
                            t("SuccessfullyDissociatedChecklistFromNode", { keyPrefix: Nodes }),
                        ])
                    );

                    setDissociateChecklistId(null);
                    setShowDissociateChecklistAssignmentModal(false);

                    queryClient.refetchQueries([
                        "filterChecklistsByNode",
                        nodeId,
                        assignedChecklistsPaginationDto,
                    ]);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        DissociateChecklistFromNodeEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    // Label building
    const buildTrainingBanner = (): ReactElement<typeof SbRibbon> | false =>
        (nodeResponseData!.isTraining || nodeDetailsResponseData!.inheritedTrainingFlag) && (
            <SbRibbon size={"large"} label={"Training"} />
        );

    const buildLegacyIdLabel = (): string | false => isLegacyIdPresent() && "Legacy Id";

    const buildIsTrainingLabel = (): string =>
        showInheritedTrainingFlagData()
            ? `${t("IsTrainingInheritedDisplay", { keyPrefix: Nodes })}`
            : `${t("IsTrainingDisplay", { keyPrefix: Common })}`;

    const buildSuspensionDateFromLabel = (): string | false =>
        showSuspensionDates(nodeResponseData!.suspensionDateTimeFrom) && "Suspend From";

    const buildSuspensionDateToLabel = (): string | false =>
        showSuspensionDates(nodeResponseData!.suspensionDateTimeTo) && "Suspend To";

    // Field value building
    const buildHierarchyLevelValueLink = (): ReactElement<typeof Link> | string =>
        nodeResponseData!.nodeTypeValue != null ? (
            <SbLink
                variant="primary"
                label={nodeResponseData!.nodeTypeValue.value}
                navigateTo={`${getPath(AccordionTitles.VisualStructureValues)}/${
                    nodeResponseData!.nodeTypeValue.nodeTypeValueId
                }`}
            />
        ) : (
            "-"
        );

    const buildNonWorkingDaySetLink = (): ReactElement<typeof Link> | string => {
        const buildLink = (
            workingDaySet: NonWorkingDaySetDto | null,
            isInherited: boolean
        ): ReactElement<typeof Link> | string =>
            workingDaySet != null ? (
                <>
                    {isInherited && (
                        <div>
                            No non working day set directly associated, inherits from an ancestor
                            node
                        </div>
                    )}
                    <SbLink
                        variant="primary"
                        label={workingDaySet.name}
                        navigateTo={`${getPath(DrawerTitles.NonWorkingDay)}/${
                            workingDaySet.nonWorkingDaySetId
                        }`}
                    />
                </>
            ) : (
                "-"
            );

        return showInheritedNonWorkingDaySetData()
            ? buildLink(nodeDetailsResponseData!.inheritedNonWorkingDaySet, true)
            : buildLink(nodeResponseData!.nonWorkingDaySet, false);
    };

    const buildParentNodeLinks = (): ReactElement<typeof Link>[] | string => {
        const parentNodes = nodeResponseData!.parentNodes;

        return parentNodes.length > 0
            ? parentNodes.map((x, index) => (
                  <SbLink
                      key={index}
                      variant="primary"
                      label={x.longDescription}
                      navigateTo={`${getPath(AccordionTitles.VisualTree)}/${x.nodeId}`}
                  />
              ))
            : "-";
    };

    const buildSuspensionDateTimeValue = (suspensionDate: Date | null): string | null => {
        if (suspensionDate != null) {
            return toDateAndTimeFormat(suspensionDate.toString());
        } else if (nodeResponseData!.isSuspended) {
            return "Not Set";
        }

        return null;
    };

    // Link item building
    const buildLinks = (): ReactElement<typeof Link> => {
        return (
            <>
                {hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.SuspendRoles) &&
                    buildSuspendActiveLinks()}
                {hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.PromoteDemoteRoles) &&
                    buildPromoteDemoteLinks()}
                {buildViewInHierarchyLink()}
                {hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.WriteRoles) && buildEditLink()}
            </>
        );
    };

    const buildSuspendActiveLinks = (): ReactElement<typeof Link> | null => {
        const showSuspendActiveLinks =
            !nodeResponseData!.isTraining &&
            !nodeDetailsResponseData!.inheritedTrainingFlag &&
            hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.SuspendRoles);

        if (showSuspendActiveLinks) {
            return nodeResponseData!.isSuspended ? (
                <ActivateLink
                    navigateTo={`${getPath(AccordionTitles.VisualTree)}/${nodeId}/activate`}
                />
            ) : (
                <SuspendLink
                    navigateTo={`${getPath(AccordionTitles.VisualTree)}/${nodeId}/suspend`}
                />
            );
        }

        return null;
    };

    const buildPromoteDemoteLinks = (): ReactElement<typeof Link> | null => {
        const showPromoteDemoteLinks =
            hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.PromoteDemoteRoles) &&
            nodeDetailsResponseData!.inheritedTrainingFlag == nodeResponseData!.isTraining &&
            !nodeResponseData!.isSuspended;

        if (showPromoteDemoteLinks) {
            return nodeResponseData!.isTraining ? (
                <PromoteLink
                    navigateTo={`${getPath(AccordionTitles.VisualTree)}/${nodeId}/promote`}
                />
            ) : (
                <DemoteLink
                    navigateTo={`${getPath(AccordionTitles.VisualTree)}/${nodeId}/demote`}
                />
            );
        }

        return null;
    };

    const buildViewInHierarchyLink = (): ReactElement<typeof Link> => (
        <SbLink
            variant="primary"
            label={t("ViewInTreeView", { keyPrefix: HierarchyTypes })}
            icon={FaSitemap}
            navigateTo={buildViewInHierarchyNavigateTo()}
        />
    );

    const buildViewInHierarchyNavigateTo = (): Partial<Path> => {
        const params = [createNavigateSearchParameter("viewInHierarchyId", nodeId.toString())];

        return createNavigateToForSearch(getPath(AccordionTitles.VisualTree), params);
    };

    const buildEditLink = (): ReactElement<typeof Link> | false =>
        nodeResponseData!.isEditable &&
        hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.WriteRoles) && (
            <EditLink navigateTo={`${getPath(AccordionTitles.VisualTree)}/${nodeId}/edit`} />
        );

    // Child nodes section building
    const buildAddChildButton = (): ReactElement<typeof Link> | false =>
        hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.WriteRoles) && (
            <EndAlignedDiv>
                <CreateLink
                    label={t("AddChild", { keyPrefix: Common })}
                    navigateTo={`${getPath(AccordionTitles.VisualTree)}/${nodeId}/create`}
                />
            </EndAlignedDiv>
        );

    const buildChildNodes = (): ReactElement<HTMLTableElement | TextSpan> => {
        if (!childNodesResponseData!.rows.length) {
            return <NoDataStateDiv> {"No child nodes"} </NoDataStateDiv>;
        }

        return (
            <>
                {/* TODO: Multiple DataTable column links */}
                <DataTable
                    keyPrefix={Hierarchy}
                    columns={associatedNodesColumnNames}
                    rows={childNodesResponseData!.rows}
                    viewItem={(metadata: BaseNodeDto) =>
                        navigate(`${getPath(AccordionTitles.VisualTree)}/${metadata.nodeId}`)
                    }
                    deleteItem={
                        hasRoleTypeInGroup(auth.userRoles, NodeRoleGroup.DeleteRoles)
                            ? (metadata: BaseNodeDto) => {
                                  const params = [
                                      createNavigateSearchParameter(
                                          "parentNodeId",
                                          nodeId.toString()
                                      ),
                                  ];

                                  navigateSearch(
                                      `${getPath(AccordionTitles.VisualTree)}/${
                                          metadata.nodeId
                                      }/delete`,
                                      params
                                  );
                              }
                            : undefined
                    }
                    totalItems={childNodesResponseData!.recordCount}
                    paginationDto={childNodesPaginationDto}
                    setPaginationDto={setChildNodesPaginationDto}
                />
            </>
        );
    };

    // Linked nodes section building
    const buildLinkedNodes = (): ReactElement<HTMLTableElement | TextSpan> => {
        if (hasLinkedToNodeTypesResponseData == false) {
            return <NoDataStateDiv> {"This node has no linked node types"} </NoDataStateDiv>;
        }

        if (!linkedNodesResponseData?.rows?.length) {
            return <NoDataStateDiv> {"No linked nodes"} </NoDataStateDiv>;
        }

        return (
            <>
                {/* TODO: Multiple DataTable column links */}
                <DataTable
                    columns={associatedNodesColumnNames}
                    keyPrefix={NodeTypes}
                    rows={linkedNodesResponseData!.rows}
                    viewItem={(metadata: BaseNodeDto) =>
                        navigate(`${getPath(AccordionTitles.VisualTree)}/${metadata.nodeId}`)
                    }
                    totalItems={linkedNodesResponseData!.recordCount}
                    paginationDto={linkedNodesPaginationDto}
                    setPaginationDto={setLinkedNodesPaginationDto}
                />
            </>
        );
    };

    // Assigned checklists section building
    const buildAssignedChecklists = (): ReactElement<HTMLTableElement | TextSpan> => {
        if (
            !assignedChecklistsResponseData?.rows?.length &&
            nodeResponseData?.isChecklistAssignable == true
        ) {
            return <NoDataStateDiv> {"No assigned checklists"} </NoDataStateDiv>;
        }

        return (
            <>
                {nodeResponseData?.isChecklistAssignable == false && (
                    <NoDataStateDiv>
                        {t("NodeIsNotChecklistAssignable", {
                            keyPrefix: ChecklistQuestions,
                        })}
                    </NoDataStateDiv>
                )}
                {assignedChecklistsResponseData!.rows.length > 0 && (
                    <DataTable
                        columns={nameColumnNames}
                        rows={assignedChecklistsResponseData!.rows}
                        deleteItem={
                            hasRoleTypeInGroup(auth.userRoles, ChecklistRoleGroup.WriteRoles)
                                ? (checklistId: number) => {
                                      setDissociateChecklistId(checklistId);
                                      setShowDissociateChecklistAssignmentModal(true);
                                  }
                                : undefined
                        }
                        totalItems={assignedChecklistsResponseData!.recordCount}
                        paginationDto={assignedChecklistsPaginationDto}
                        setPaginationDto={setAssignedChecklistsPaginationDto}
                    />
                )}
            </>
        );
    };

    const buildAssignedActionItems = (): ReactElement<HTMLTableElement> => {
        return (
            <>
                <AssignedActionItemsToNodeContainer nodeId={nodeId} />
            </>
        );
    };

    // Assigned users section building
    const buildAssociateUsersFields = (): ReactElement<typeof Link> | false =>
        hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles) &&
        nodeResponseData?.isUserAssignable === true && (
            <EndAlignedDiv>
                <Col sm={2}>
                    <SbSelect
                        name={"users"}
                        placeholderText={`${t("SearchForUser", {
                            keyPrefix: Users,
                        })}`}
                        searchable
                        clearable
                        styles={maxContentWidthSelectStyle}
                        items={filterUsers?.data}
                        loading={filterUsers.isFetching}
                        itemLabel={(user: BaseUserDto) => `${user.fullName} - ${user.emailAddress}`}
                        onSearchTextChanged={(text: string) => setUserSearchText(text)}
                        value={assigningUser}
                        onChange={(userOption: SingleValue<BaseUserDto>) =>
                            setAssigningUser(userOption)
                        }
                    />
                </Col>
                <AssociateButton onClick={onAssignUser} disabled={!assigningUser} />
            </EndAlignedDiv>
        );

    const buildAssignedUsers = (): ReactElement<HTMLTableElement | TextSpan> => {
        if (!assignedUsersResponseData?.rows.length) {
            return <NoDataStateDiv> {"No assigned users"} </NoDataStateDiv>;
        }

        return (
            <DataTable
                columns={nameColumnNames}
                rows={assignedUsersResponseData!.rows}
                deleteItem={
                    hasRoleTypeInGroup(auth.userRoles, UserRoleGroup.AssignRoles)
                        ? (userId: number) => {
                              const params = [
                                  createNavigateSearchParameter("nodeId", nodeId.toString()),
                                  createNavigateSearchParameter("userId", userId.toString()),
                              ];

                              navigateSearch(
                                  `${getPath(AccordionTitles.VisualTree)}/dissociate-user`,
                                  params
                              );
                          }
                        : undefined
                }
                totalItems={assignedUsersResponseData!.recordCount}
                paginationDto={assignedUsersPaginationDto}
                setPaginationDto={setAssignedUsersPaginationDto}
            />
        );
    };

    return (
        <>
            <PageHeading>{t("NodeDetailsTitle")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextNodeDetails")}</PageSubHeading>
            <SectionVerticalSpace />

            {areQueriesSuccessful([...getNodeAndChildNodeDetails, assignedChecklistsResponse]) && (
                <>
                    <ContentContainer>
                        {buildTrainingBanner()}
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("HierarchyLevel", { keyPrefix: Hierarchy })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {
                                        <SbLink
                                            variant="primary"
                                            label={nodeResponseData!.nodeType.name}
                                            navigateTo={`${getPath(
                                                AccordionTitles.VisualStructure
                                            )}/${nodeResponseData!.nodeType.nodeTypeId}`}
                                        />
                                    }
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("NodeTypeValue", { keyPrefix: NodeTypeValues })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>{buildHierarchyLevelValueLink()}</DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("NonWorkingDaySet", { keyPrefix: NonWorkingDays })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>{buildNonWorkingDaySetLink()}</DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("ParentNodes", { keyPrefix: Nodes })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>{buildParentNodeLinks()}</DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>{buildLegacyIdLabel()}</DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>{nodeResponseData!.legacyId}</DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>{t("Path", { keyPrefix: Common })}</DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>{nodeResponseData!.path ?? "-"}</DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("CreatedByUser", { keyPrefix: Users })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {nodeResponseData!.createdByUserFullName ?? "-"}
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("CreatedByUserEmail", { keyPrefix: Users })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {nodeResponseData!.createdByUserName ?? "-"}
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("DateCreated", { keyPrefix: Common })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {nodeResponseData!.dateCreatedLocal ?? "-"}
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("LastModifiedBy", { keyPrefix: Users })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {nodeResponseData!.modifiedByUserFullName ?? "-"}
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("ModifiedByUserEmail", { keyPrefix: Users })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {nodeResponseData!.modifiedByUserName ?? "-"}
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("DateModified", { keyPrefix: Common })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {nodeResponseData!.dateModifiedLocal ?? "-"}
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>{buildIsTrainingLabel()}</DetailsLabel>
                            </Col>
                            <Col md="10">
                                <SbCheckField
                                    type={"checkbox"}
                                    name={"isTraining"}
                                    defaultSelected={isTrainingNode()}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>
                                    {t("IsSuspended", { keyPrefix: Nodes })}
                                </DetailsLabel>
                            </Col>
                            <Col md="10">
                                <SbCheckField
                                    type={"checkbox"}
                                    name={"isSuspended"}
                                    defaultSelected={nodeResponseData!.isSuspended}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>{buildSuspensionDateFromLabel()}</DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {buildSuspensionDateTimeValue(
                                        nodeResponseData!.suspensionDateTimeFrom
                                    )}
                                </DetailsValue>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <DetailsLabel>{buildSuspensionDateToLabel()}</DetailsLabel>
                            </Col>
                            <Col md="10">
                                <DetailsValue>
                                    {buildSuspensionDateTimeValue(
                                        nodeResponseData!.suspensionDateTimeTo
                                    )}
                                </DetailsValue>
                            </Col>
                        </Row>

                        <EndAlignedDiv>{buildLinks()}</EndAlignedDiv>
                    </ContentContainer>
                    <SectionVerticalSpace />
                </>
            )}

            {childNodesResponse.isSuccess && (
                <>
                    <SbAccordion title={t("ListOfChildNodesAssociatedWithHierarchyNodes")}>
                        {buildAddChildButton()}
                        {buildChildNodes()}
                    </SbAccordion>
                    <SectionVerticalSpace />
                </>
            )}

            {(getLinkedNodeDetails.isSuccess || hasLinkedToNodeTypesResponseData == false) && (
                <>
                    <SbAccordion title={t("ListOfLinkedNodesAssociatedWithThisNode")}>
                        {buildLinkedNodes()}
                    </SbAccordion>
                    <SectionVerticalSpace />
                </>
            )}

            {(assignedChecklistsResponse.isSuccess ||
                nodeResponseData?.isChecklistAssignable == false) && (
                <>
                    <SbAccordion title={t("ListOfChecklistsAssignedToThisHierarchyNode")}>
                        {buildAssignedChecklists()}
                    </SbAccordion>
                    <SectionVerticalSpace />
                </>
            )}

            <>
                <SbAccordion title={t("ListOfActionItemsAssignedToThisHierarchyNode")}>
                    {buildAssignedActionItems()}
                </SbAccordion>
                <SectionVerticalSpace />
            </>

            {(assignedUsersResponse.isSuccess || nodeResponseData?.isUserAssignable == false) && (
                <>
                    <SbAccordion
                        title={t("ListOfUsersAssignedToThisHierarchyNode", { keyPrefix: Users })}
                    >
                        {buildAssociateUsersFields()}
                        {buildAssignedUsers()}
                    </SbAccordion>
                    <SectionVerticalSpace />
                </>
            )}

            <SbModal
                title={t("DissociateChecklistNode", { keyPrefix: ChecklistQuestions })}
                body={t("DissociateChecklistNodeConfirmationDisplay", {
                    keyPrefix: ChecklistQuestions,
                })}
                isVisible={showDissociateChecklistAssignmentModal}
                updateIsVisible={setShowDissociateChecklistAssignmentModal}
                primaryButtonLabel={`${t("Yes", { keyPrefix: Common })}`}
                secondaryButtonLabel={`${t("No", { keyPrefix: Common })}`}
                onPrimaryButtonClicked={handleDissociateChecklist}
            />
        </>
    );
};

export default NodeDetailsContainer;
