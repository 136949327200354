import {
    useMutation,
    UseMutationResult,
    useQueries,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import { useUrl } from "../../../core/store/url-context";
import { AccordionTitles, DataTableColumnTypes } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import { filterChecklistsByNode } from "../../../data/services/checklists/checklist-service";
import { filterLinkedToNodeTypes } from "../../../data/services/hierarchy/node-types-service";
import {
    assignUserToNode,
    filterChildNodes,
    filterLinkedNodes,
    getNodeDetails,
} from "../../../data/services/hierarchy/nodes-service";
import { filterUsers, filterUsersByNode } from "../../../data/services/users/users-service";
import { PaginatedTableDto } from "../../dtos/common/paginated-table-dto";
import { PaginationDto } from "../../dtos/common/pagination-dto";
import { BaseNodeDto, createBaseNodeDto } from "../../dtos/hierarchy/base-node-dto";
import { createNodeDetailsDto, NodeDetailsDto } from "../../dtos/hierarchy/node-details-dto";
import {
    BaseUserDto,
    toBaseUserDtosFromPaginatedUserResponse,
} from "../../dtos/users/base-user-dto";
import { createFilterByNodeRequestFromPaginationDto } from "../../requests/common/filter-by-node-request";
import { createPaginationRequestFromDto } from "../../requests/common/pagination-request";
import { createFilterByNodeTypeRequest } from "../../requests/hierarchy/filter-by-node-type-request";
import { createFilterChildNodesRequest } from "../../requests/hierarchy/filter-child-nodes-request";
import { createUserNodeAssociationRequest } from "../../requests/hierarchy/user-node-association-request";
import { createFilterUsersSearchRequest } from "../../requests/users/filter-users-request";
import { ChecklistResponse } from "../../responses/checklists/checklist-response";
import { PaginationResponse } from "../../responses/common/pagination-response";
import { Response } from "../../responses/common/response-response";
import { BaseNodeResponse } from "../../responses/hierarchy/base-node-response";
import { NodeTypeResponse } from "../../responses/hierarchy/node-type-response";
import { BaseUserResponse } from "../../responses/users/base-user-response";
import { UserNodeAssociationParameters } from "./dissociate-user-viewmodel";

export const useGetNodeAndChildNodeDetails = (
    nodeId: number,
    childNodesPaginationDto: PaginationDto
): [
    UseQueryResult<NodeDetailsDto, HTTPError>,
    UseQueryResult<PaginatedTableDto<BaseNodeDto>, HTTPError>,
] => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const { pageNumber: childNodesPageNumber, pageSize: childNodesPageSize } =
        childNodesPaginationDto;

    return useQueries({
        queries: [
            {
                queryKey: ["getNodeDetails", nodeId],
                queryFn: () => getNodeDetails(url.baseUrl, nodeId),
                select: createNodeDetailsDto,
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterChildNodes", nodeId, childNodesPageNumber, childNodesPageSize],
                queryFn: () =>
                    filterChildNodes(
                        url.baseUrl,
                        createFilterChildNodesRequest(
                            createPaginationRequestFromDto(childNodesPaginationDto),
                            nodeId
                        )
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<BaseNodeResponse>>) =>
                    transformToBaseNodeDtoDataTableRows(response.data, navigate),
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<NodeDetailsDto, HTTPError>,
        UseQueryResult<PaginatedTableDto<BaseNodeDto>, HTTPError>,
    ];
};

export const useHasLinkedToNodeTypes = (
    nodeTypeId: number | null
): UseQueryResult<boolean, HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterLinkedToNodeTypes", nodeTypeId],
        () =>
            filterLinkedToNodeTypes(url.baseUrl, createFilterByNodeTypeRequest(1, 1, nodeTypeId!)),
        {
            enabled: nodeTypeId != null,
            select: toDoesHaveNodeTypes,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetLinkedNodeDetails = (
    nodeId: number,
    hasLinkedNodeTypes: boolean | null,
    linkedNodesPaginationDto: PaginationDto
): UseQueryResult<PaginatedTableDto<BaseNodeDto>, HTTPError> => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterLinkedNodes", nodeId, linkedNodesPaginationDto],
        () =>
            filterLinkedNodes(
                url.baseUrl,
                createFilterByNodeRequestFromPaginationDto(nodeId, linkedNodesPaginationDto)
            ),
        {
            enabled: hasLinkedNodeTypes == true,
            keepPreviousData: true,
            select: (response: Response<PaginationResponse<BaseNodeResponse>>) =>
                transformToBaseNodeDtoDataTableRows(response.data, navigate),
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useGetAssignedToNodeDetails = (
    nodeId: number,
    isUserAssignable: boolean | null,
    assignedUsersPaginationDto: PaginationDto,
    assignedChecklistsPaginationDto: PaginationDto
): [
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    UseQueryResult<PaginatedTableDto<number>, HTTPError>,
] => {
    const url = useUrl();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQueries({
        queries: [
            {
                queryKey: ["filterUsersByNode", nodeId, assignedUsersPaginationDto],
                queryFn: () =>
                    filterUsersByNode(
                        url.baseUrl,
                        createFilterByNodeRequestFromPaginationDto(
                            nodeId,
                            assignedUsersPaginationDto
                        )
                    ),
                enabled: isUserAssignable == true,
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<BaseUserResponse>>) =>
                    transformToUserDtoDataTableRows(response.data, navigate),
                onError: errorResponseToDisplayHandler,
            },
            {
                queryKey: ["filterChecklistsByNode", nodeId, assignedChecklistsPaginationDto],
                queryFn: () =>
                    filterChecklistsByNode(
                        url.baseUrl,
                        createFilterByNodeRequestFromPaginationDto(
                            nodeId,
                            assignedChecklistsPaginationDto
                        )
                    ),
                keepPreviousData: true,
                select: (response: Response<PaginationResponse<ChecklistResponse>>) =>
                    transformToChecklistDataTableRows(response.data, navigate),
                onError: errorResponseToDisplayHandler,
            },
        ],
    }) as [
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
        UseQueryResult<PaginatedTableDto<number>, HTTPError>,
    ];
};

export const useFilterUsers = (
    pageNumber: number,
    pageSize: number,
    searchText: string | null
): UseQueryResult<BaseUserDto[], HTTPError> => {
    const url = useUrl();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    return useQuery(
        ["filterUsers", pageNumber, pageSize, searchText],
        () =>
            filterUsers(
                url.baseUrl,
                createFilterUsersSearchRequest(searchText!, pageNumber, pageSize, false)
            ),
        {
            enabled: searchText != null && searchText.trim().length > 0,
            select: toBaseUserDtosFromPaginatedUserResponse,
            onError: errorResponseToDisplayHandler,
        }
    );
};

export const useAssignUserToNode = (): UseMutationResult<
    Response<boolean>,
    HTTPError,
    UserNodeAssociationParameters
> => {
    const url = useUrl();

    return useMutation((mutationKey: UserNodeAssociationParameters) => {
        const { nodeId, userId } = mutationKey;

        return assignUserToNode(url.baseUrl, createUserNodeAssociationRequest(nodeId, userId));
    });
};

const transformToBaseNodeDtoDataTableRows = (
    response: PaginationResponse<BaseNodeResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<BaseNodeDto> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((x) => ({
        metadata: createBaseNodeDto(x),
        columns: [
            {
                value: x.nodeType?.name ?? "-",
                type: DataTableColumnTypes.Link,
                linkItemAction: (item: BaseNodeDto) =>
                    navigate(
                        `${getPath(AccordionTitles.VisualStructure)}/${item.nodeType.nodeTypeId}`
                    ),
            },
            {
                value: x.shortDescription,
                type: DataTableColumnTypes.Link,
                linkItemAction: (item: BaseNodeDto) =>
                    navigate(
                        `${getPath(AccordionTitles.VisualStructureValues)}/${
                            item.nodeTypeValue?.nodeTypeValueId
                        }`
                    ),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const toDoesHaveNodeTypes = (response: Response<PaginationResponse<NodeTypeResponse>>): boolean => {
    const responseData = response.data.results;

    return responseData != null && responseData.length > 0;
};

const transformToUserDtoDataTableRows = (
    response: PaginationResponse<BaseUserResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((x) => ({
        metadata: x.userId,
        columns: [
            {
                value: `${x.firstName} ${x.lastName}`,
                type: DataTableColumnTypes.Link,
                linkItemAction: (userId: number) =>
                    navigate(`${getPath(AccordionTitles.Users)}/${userId}`),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};

const transformToChecklistDataTableRows = (
    response: PaginationResponse<ChecklistResponse>,
    navigate: NavigateFunction
): PaginatedTableDto<number> => {
    const { pageCount, pageSize, currentPage, recordCount } = response;

    const rows = response.results!.map((x) => ({
        metadata: x.checklistId,
        columns: [
            {
                value: x.name,
                type: DataTableColumnTypes.Link,
                linkItemAction: (checklistId: number) =>
                    navigate(`${getPath(AccordionTitles.Checklists)}/${checklistId}`),
            },
        ],
    }));

    return {
        numberOfPages: pageCount,
        pageSize: pageSize,
        currentPage: currentPage,
        recordCount: recordCount,
        rows: rows,
    };
};
