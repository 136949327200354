import { HTTPError } from "ky";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaLink, FaPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { AssociateQuestionSetToChecklistEvent } from "../../../core/constants/application-insights-events";
import { EnterKey } from "../../../core/constants/KeyboardKeys";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useKeyPress from "../../../core/hooks/keyPress";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { associateQuestionSetColumnNames } from "../../../core/utilities/dataTableColumns";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    isMutationLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import queryClient from "../../../data/query-client";
import {
    defaultFilterQuestionSetDto,
    FilterQuestionSetDto,
} from "../../../domain/dtos/question-sets/filter-question-set-dto";
import {
    useAssociateExistingQuestionSet,
    useGetAssociateQuestionSets,
} from "../../../domain/viewmodels/checklists/associate-existing-question-set-viewmodel";
import { CancelButton, SbButton } from "../../atoms/SbButton";
import { DataTable } from "../../organisms/DataTable";
import { QuestionSetsFilter } from "../../organisms/filters/QuestionSetsFilter";
import { defaultBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";

interface SearchParams {
    questionSetName: string | null;
    description: string | null;
    questionText: string | null;
    processName: string | null;
    questionSetId: number | null;
}

const createSearchParams = (
    questionSetName: string | null,
    description: string | null,
    questionText: string | null,
    processName: string | null,
    questionSetId: number | null
): SearchParams => ({
    questionSetName: questionSetName,
    description: description,
    questionText: questionText,
    processName: processName,
    questionSetId: questionSetId,
});

const defaultSearchParams: SearchParams = createSearchParams(null, null, null, null, null);

const AssociateQuestionSetContainer: React.FC = () => {
    const [selectedQuestionSets, setSelectedQuestionSets] = useState<number[]>([]);

    const [searchParams, setSearchParams] = useState<SearchParams>(defaultSearchParams);
    const [filterDto, setFilterDto] = useState<FilterQuestionSetDto>(defaultFilterQuestionSetDto);

    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const menu = useMenu();
    const auth = useAuth();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    const checklistId = Number(useParams().checklistId);

    const getAssociateQuestionSets = useGetAssociateQuestionSets(checklistId, filterDto);
    const associateQuestionSet = useAssociateExistingQuestionSet();

    useLoader(
        areQueriesLoading(getAssociateQuestionSets) || isMutationLoading(associateQuestionSet),
        AssociateQuestionSetContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.Checklists);
    }, []);

    const updateQuestionSetNameFilterValue = (questionSetName: string): void => {
        setSearchParams({
            ...searchParams,
            questionSetName: questionSetName !== "" ? questionSetName : null,
        });
    };

    const updateQuestionSetDescriptionFilterValue = (description: string): void => {
        setSearchParams({
            ...searchParams,
            description: description !== "" ? description : null,
        });
    };

    const updateQuestionSetQuestionTextFilterValue = (questionText: string): void => {
        setSearchParams({
            ...searchParams,
            questionText: questionText !== "" ? questionText : null,
        });
    };

    const updateQuestionSetProcessNameFilterValue = (processName: string): void => {
        setSearchParams({
            ...searchParams,
            processName: processName !== "" ? processName : null,
        });
    };

    const search = (): void => {
        setFilterDto({ ...filterDto, pageNumber: 1, ...searchParams });
    };

    const resetFilter = (): void => {
        setSearchParams(defaultSearchParams);
        setFilterDto({ ...filterDto, ...defaultBasePaginationDto, ...defaultSearchParams });
    };

    const navigateToViewQuestionSetDetails = (id: number): void =>
        navigate(`${getPath(AccordionTitles.QuestionSets)}/${id}`);

    const selectQuestionSet = (questionSetId: number): void => {
        const selectedQuestionSet = questionSetId;
        const selectedQuestionSetsArr = [...selectedQuestionSets];

        const index = selectedQuestionSetsArr.indexOf(selectedQuestionSet);

        if (index > -1) {
            selectedQuestionSetsArr.splice(index, 1);
        } else {
            selectedQuestionSetsArr.push(selectedQuestionSet);
        }

        setSelectedQuestionSets(selectedQuestionSetsArr);
    };

    const selectedQuestionSet = (questionSetId: number): boolean => {
        const selectedQuestionSet = questionSetId;
        const selectedQuestionSetsArr = [...selectedQuestionSets];

        return selectedQuestionSetsArr.includes(selectedQuestionSet);
    };

    const handleAssociateQuestionSet = (): void => {
        associateQuestionSet.mutate(
            {
                checklistId: checklistId,
                questionSetIds: selectedQuestionSets,
            },
            {
                onSuccess: async () => {
                    trackAppInsightsEvent(
                        auth.email,
                        window.location.href,
                        AssociateQuestionSetToChecklistEvent
                    );
                    queryClient.invalidateQueries(["getAssociatedQuestionSets"]);

                    const params = [
                        createNavigateSearchParameter("success", "true"),
                        createNavigateSearchParameter(
                            "messageKey",
                            "AssociateQuestionSetToChecklistSuccessMessage"
                        ),
                    ];

                    navigateSearch(`${getPath(AccordionTitles.Checklists)}/${checklistId}`, params);
                },
                onError: (error: HTTPError) => {
                    trackAppInsightsException(
                        auth.email,
                        window.location.href,
                        AssociateQuestionSetToChecklistEvent,
                        error
                    );
                    errorResponseToDisplayHandler(error);
                },
            }
        );
    };

    useKeyPress(EnterKey, search, searchParams);

    return (
        <>
            <PageHeading>{t("AssociateQuestionSet")}</PageHeading>
            <PageSubHeading>{t("HeaderHelpTextAssociateQuestionSet")}</PageSubHeading>
            <LargeVerticalSpace />

            {isQuerySuccessful(getAssociateQuestionSets[0]) && (
                <>
                    <ContentContainer>
                        <Row>
                            <Col md="auto">
                                <DetailsLabel>{t("ChecklistName")}</DetailsLabel>
                            </Col>
                            <Col md="auto">
                                <DetailsValue>{getAssociateQuestionSets[0].data}</DetailsValue>
                            </Col>
                        </Row>
                    </ContentContainer>
                    <LargeVerticalSpace />
                </>
            )}

            <QuestionSetsFilter
                questionSetName={searchParams.questionSetName}
                questionSetNameFilterValue={updateQuestionSetNameFilterValue}
                description={searchParams.description}
                descriptionFilterValue={updateQuestionSetDescriptionFilterValue}
                questionText={searchParams.questionText}
                questionTextFilterValue={updateQuestionSetQuestionTextFilterValue}
                processName={searchParams.processName}
                processNameFilterValue={updateQuestionSetProcessNameFilterValue}
                search={search}
                resetFilter={resetFilter}
            />
            <SectionVerticalSpace />

            {isQuerySuccessful(getAssociateQuestionSets[1]) && (
                <>
                    <DataTable
                        keyPrefix={ChecklistQuestions}
                        columns={associateQuestionSetColumnNames}
                        rows={getAssociateQuestionSets[1].data!.rows}
                        linkItem={navigateToViewQuestionSetDetails}
                        selectItem={selectQuestionSet}
                        selectedItem={selectedQuestionSet}
                        totalItems={getAssociateQuestionSets[1].data!.recordCount}
                        paginationDto={filterDto}
                        setPaginationDto={setFilterDto}
                    />
                    <LargeVerticalSpace />

                    <EndAlignedDiv>
                        <Col sm={"auto"}>
                            <SbButton
                                icon={FaLink}
                                variant={"primary"}
                                type={"button"}
                                label={"Associate Selected"}
                                onClick={handleAssociateQuestionSet}
                                disabled={selectedQuestionSets.length === 0}
                            />
                        </Col>
                        <Col sm={"auto"}>{"or"}</Col>
                        <Col sm={"auto"}>
                            <SbButton
                                icon={FaPlus}
                                variant={"primary"}
                                type={"button"}
                                label={"Associate New"}
                                onClick={() => {
                                    const params = [
                                        createNavigateSearchParameter(
                                            "checklistId",
                                            checklistId.toString()
                                        ),
                                    ];

                                    return navigateSearch(
                                        `${getPath(AccordionTitles.QuestionSets)}/create`,
                                        params
                                    );
                                }}
                            />
                        </Col>
                        <Col sm={"auto"}>
                            <CancelButton onClick={() => navigate(-1)} />
                        </Col>
                    </EndAlignedDiv>
                </>
            )}
        </>
    );
};

export default AssociateQuestionSetContainer;
