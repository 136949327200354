import { HTTPError } from "ky";
import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SoftDeleteNodeEvent } from "../../../core/constants/application-insights-events";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    trackAppInsightsEvent,
    trackAppInsightsException,
} from "../../../core/utilities/application-insights-helper";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
} from "../../../core/utilities/responseStateHelper";
import {
    useGetNodeDetails,
    useSoftDeleteNode,
} from "../../../domain/viewmodels/hierarchy/soft-delete-node-viewmodel";
import { CancelButton, DeleteButton } from "../../atoms/SbButton";
import { TextConfirmationRow } from "../../organisms/ActionConfirmation";
import {
    ActionConfirmationFragment,
    AlertProps,
    createErrorAlertProps,
} from "../../organisms/ActionConfirmationFragment";

const SoftDeleteNodeContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const auth = useAuth();
    const navigateSearch = useNavigateSearch();
    const [urlSearchParams] = useSearchParams();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const parentNodeId = Number(urlSearchParams.get("parentNodeId"));
    const nodeId = Number(useParams().nodeId);

    const getNodeDetails = useGetNodeDetails(nodeId);
    const softDeleteNode = useSoftDeleteNode();

    const nodeDetailsResponseData = getNodeDetails[0].data?.node;
    const canSoftDeleteNodeResponseData = getNodeDetails[1].data;

    useLoader(
        areQueriesLoading(getNodeDetails) || isMutationLoading(softDeleteNode),
        SoftDeleteNodeContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Hierarchy, AccordionTitles.VisualTree);
    }, []);

    const handleSoftDeleteNode = (): void => {
        softDeleteNode.mutate(nodeId, {
            onSuccess: onSoftDeleteNodeSuccess,
            onError: (error: HTTPError) => {
                trackAppInsightsException(
                    auth.email,
                    window.location.href,
                    SoftDeleteNodeEvent,
                    error
                );
                errorResponseToDisplayHandler(error);
            },
        });
    };

    const onSoftDeleteNodeSuccess = async (): Promise<void> => {
        trackAppInsightsEvent(auth.email, window.location.href, SoftDeleteNodeEvent);

        const params = [
            createNavigateSearchParameter("success", "true"),
            createNavigateSearchParameter("messageKey", "DeleteNodeSuccessMessage"),
        ];

        navigateSearch(`${getPath(AccordionTitles.VisualTree)}/${parentNodeId}`, params);
    };

    const handleCancelNavigation = (): void => {
        navigate(`${getPath(AccordionTitles.VisualTree)}/${parentNodeId}`);
    };

    const buildDeleteButton = (): ReactElement<typeof Button> => (
        <DeleteButton
            onClick={handleSoftDeleteNode}
            disabled={canSoftDeleteNodeResponseData?.data === false}
        />
    );

    const buildCancelButton = (): ReactElement<typeof Button> => (
        <CancelButton onClick={handleCancelNavigation} />
    );

    const buildValidationAlert = (): AlertProps | null => {
        if (!canSoftDeleteNodeResponseData || canSoftDeleteNodeResponseData.data) {
            return null;
        }

        return createErrorAlertProps(canSoftDeleteNodeResponseData!.errors);
    };

    const buildConfirmationRows = (): TextConfirmationRow[] => [
        new TextConfirmationRow("Hierarchy Level", nodeDetailsResponseData?.nodeType.name),
        new TextConfirmationRow(
            "Hierarchy Level Value",
            nodeDetailsResponseData?.nodeTypeValue?.value
        ),
        new TextConfirmationRow("Path", nodeDetailsResponseData?.path),
    ];

    return (
        <ActionConfirmationFragment
            pageHeading={"Node Delete"}
            panelTitle={"Are you sure you want to delete this Node?"}
            alert={buildValidationAlert()}
            rows={buildConfirmationRows()}
            primaryButton={buildDeleteButton()}
            cancelButton={buildCancelButton()}
            areQueriesSuccessful={areQueriesSuccessful(getNodeDetails)}
        />
    );
};

export default SoftDeleteNodeContainer;
